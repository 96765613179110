body{
  font-family: 'Noto Sans', sans-serif;
}
.App {
  text-align: center;
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 100%;
  border: 6px solid #000;
  /* animation: App-logo-float infinite 3s ease-in-out; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  /* min-height: 100vh; */
  /* background-color: paleturquoise; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-footer{
  padding: 16px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
  .PSB{
    display: flex;
    align-items: center;
  }
  .Icp-link{
    text-decoration: none;
    color: white;
  }
  .Icp-icon{
    display: inline-block;
    background-size: contain;
    width: 16px;
    height: 17.777777px;
    background-repeat: no-repeat;
    background-position: center;
    background-image:url('../public/img/beian.png');
    margin-right: .25rem;
  }
  
}

.Email-link{
  font-weight: 500;
  color: white;
  letter-spacing: 3.6px;
  z-index: 1;
  font-size: calc(10px + 2vmin);
}

footer{
  position: fixed;
  z-index: 1;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.App-link {
  color: white;
  text-decoration: none;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}


.area{
  background: linear-gradient(to top, #8f94fb, #4e54c8);  
  width: 100%;
  height:100vh;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  z-index: 0;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}
